@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  font-family: "Montserrat", system-ui;
}

.body-size {
  max-width: 1950px;
  max-height: fit-content;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@layer utilities {
  .text-outline {
    color: transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 255, 0.1); /* Adjust stroke width and color as needed */
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.animated-card:hover{
  transition: all 0.3s ease-in-out;

}
.test{
  z-index: 10;
  height: 150px;
  
}
.list{
  opacity: 0;
}

.animated-card-child::after{
  content: ' ';
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
z-index: 1;
background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5));
}


.animated-card-child::after{
  content: ' ';
    display: block;
}

/*  */
